import { defineStore } from 'pinia'
import { getStarknet, type ConnectedStarknetWindowObject, type StarknetWindowObject } from 'get-starknet-core'


import type starknetjs from 'starknet'

import {
  Exchange,
  Controller,
  StarknetNetwork,
  newBnWithDecimals,
  parseInstrumentName,
  toMessageTimestamp,
  ERC20,
  getAddressBook,
  MSG_OPTION_PUT,
  MSG_OPTION_CALL,
  getExpiryTimestampByDate,
  TransactionEvent,
  setTokenApproval,
  getTokenAllowance,
  getTokenBalance,
  mintTestToken,
  Message,
  MessageOrderType,
  MintableTestToken,
  Price,
  OracleContract,

} from '@optiondance/starknet-sdk'
import BigNumber from 'bignumber.js'
import { constants, type Account } from 'starknet'



enum StarknetChainId {
  MAINNET = "0x534e5f4d41494e",
  TESTNET = "0x534e5f474f45524c49",
  TESTNET2 = "0x534e5f474f45524c4932"
}

export const getGlobalAddressBook = () => {
  const starknet = useStarknet()
  console.log('starknet.network', starknet.network);
  let network = starknet.network
  if (network === constants.NetworkName.SN_GOERLI2) {
    network = constants.NetworkName.SN_GOERLI
  }
  return getAddressBook(network)
}


export const getConnectedStarknetWindowObject = async (walletId: string): Promise<ConnectedStarknetWindowObject> => {
  const getstarknet = getStarknet()
  const wallet: StarknetWindowObject | undefined = walletId === 'argentX'
    ? window.starknet_argentX
    : window.starknet_braavos;
  if (!wallet) {
    throw Error('no wallet founded')
  }
  return await getstarknet.enable(wallet)
}


export const getStarknetObject = async (walletId?: string | undefined): Promise<ConnectedStarknetWindowObject> => {
  const getstarknet = getStarknet()
  if (walletId) {
    return await getConnectedStarknetWindowObject(walletId)
  } else {
    let lastConnected = await getstarknet.getLastConnectedWallet()
    if (lastConnected) {
      return getConnectedStarknetWindowObject(lastConnected.id)
    } else {
      throw Error('invalid walletId')
    }
  }
}


export const useStarknet = defineStore('starknet', {
  state: () => ({
    l2ActiveAccount: '',
    l2Account: {},
    provider: { chainId: '' },
    l2IsConnected: false,
    l2ChainName: 'null',
    l2InFlight: false,
    l2Count: false,
    l2TxStatus: null,
    l2TxHash: null,
    l2Error: null,
    network: constants.NetworkName.SN_GOERLI,
    addressbook: {
      wbtc: { id: "", name: 'WBTC', symbol: "WBTC", decimals: 8, },
      usdc: { id: "", name: 'USDC', symbol: "USDC", decimals: 6 },
      eth: { id: "", name: 'ETH', symbol: "ETH", decimals: 18, }
    },
    walletId: ''
  }),
  actions: {
    async connect(
      callback: (type: string, code: string, step: number, account: any) => void, walletId?: string
    ) {

      this.walletId = walletId ?? 'argentX'
      let starknet = await getStarknetObject(walletId)
      if (starknet.isConnected) {
        let walletAddress = starknet.selectedAddress
        this.l2ActiveAccount = starknet.selectedAddress
        if (starknet.id === 'braavos') {
          console.log('connected with braavos');
          this.provider = starknet.provider.provider
        } else if (starknet.id === 'argentX') {
          console.log('connected with argentX');
          this.provider = starknet.provider
        }
        

        this.l2Account = starknet.account
        this.l2IsConnected = true
        if (this.provider.chainId === StarknetChainId.TESTNET) {
          this.network = constants.NetworkName.SN_GOERLI
        } else if (this.provider.chainId === StarknetChainId.MAINNET) {
          this.network = constants.NetworkName.SN_MAIN
        } else {
          this.network = constants.NetworkName.SN_MAIN
        }
        this.addressbook = getAddressBook(this.network)
        callback('starknet', '', 1, {
          full_name: walletAddress,
          user_id: walletAddress,
        })

        starknet.on('accountsChanged', async (e) => {
          const newAddr = e[0]
          const starknet = await getStarknetObject(walletId)
          if (starknet.isConnected) {
            this.l2ActiveAccount = starknet.selectedAddress
            this.provider = starknet.provider.provider
            this.l2Account = starknet.account
            this.l2IsConnected = true
          } else {
            console.log('cannot connet to argent wallet')
          }
        })
      }
    },

    async signOrderMessage(
      instrumentName: string,
      baseAsset: ERC20,
      side: MessageOrderType,
      price: number,
      baseAmount: number,
      expiration: string,
      salt: string
    ): Promise<[Message, string]> {
      const starknet = await getStarknetObject()
      const exchange = new Exchange(this.network)
      return await exchange.signOrderMessage(
        starknet.account as starknetjs.Account,
        instrumentName,
        baseAsset,
        side,
        price,
        baseAmount,
        expiration,
        salt
      )
    },

    async fillOrder(
      buy_order: Message,
      sell_order: Message,
      fill_price: Price,
      base_fill_quantity: string,
      events: TransactionEvent
    ) {
      const starknet = await getStarknetObject()
      const exchange = new Exchange(this.network)
      return await exchange.fillOrder(
        starknet.account as starknetjs.Account,
        buy_order,
        sell_order,
        fill_price,
        base_fill_quantity,
        events
      )
    },

    async createInstrument(
      underlyingAsset: string,
      quoteAsset: string,
      strikePrice: number,
      optionType: string,
      expiry: string,
      events: TransactionEvent
    ) {
      const addressbook = getAddressBook(this.network)
      let underlyingAssetAddr = ''
      let quoteAssetAddr = ''
      let msgOptionType = MSG_OPTION_PUT

      switch (underlyingAsset) {
        case 'BTC':
          underlyingAssetAddr = addressbook.wbtc.id
          break
        case 'ETH':
          underlyingAssetAddr = addressbook.eth.id
          break
        default:
          throw Error(`wrong underlying asset ${underlyingAsset}`)
      }

      if (optionType === 'PUT') {
        if (quoteAsset === 'USDC') {
          quoteAssetAddr = addressbook.usdc.id
        } else {
          throw Error(`wrong quoteAsset ${quoteAsset}`)
        }
      }
      else {
        quoteAssetAddr = underlyingAssetAddr
      }

      if (optionType === 'CALL') {
        msgOptionType = MSG_OPTION_CALL
      }
      const expiryDate = new Date(expiry)

      const expiryTimestamp = getExpiryTimestampByDate(
        expiryDate.getUTCFullYear(),
        expiryDate.getUTCMonth() + 1,
        expiryDate.getDate()
      )

      const starknet = await getStarknetObject()
      const controller = new Controller(this.network)
      await controller.createInstrument(
        starknet.account as starknetjs.Account,
        underlyingAssetAddr,
        quoteAssetAddr,
        strikePrice,
        expiryTimestamp,
        msgOptionType,
        events
      )
    },

    async approve(
      tokenAddress: string,
      spender: string,
      amount: string,
      events: TransactionEvent
    ) {
      const starknet = await getStarknetObject()
      await setTokenApproval(
        starknet.account as starknetjs.Account,
        starknet.provider,
        tokenAddress,
        spender,
        amount,
        events
      )
    },

    async allowance(tokenAddress: string, spender: string): Promise<BigNumber> {
      const starknet = await getStarknetObject()
      const allowance = await getTokenAllowance(
        starknet.account as starknetjs.Account,
        tokenAddress,
        starknet.account.address,
        spender
      )
      return allowance
    },

    async balanceof(tokenAddress: string): Promise<BigNumber> {
      const starknet = await getStarknetObject()
      return await getTokenBalance(
        starknet.account as starknetjs.Account,
        tokenAddress,
        starknet.account.address
      )
    },

    async mintOption(
      optionToken: string,
      amount: number,
      events: TransactionEvent
    ) {
      const starknet = await getStarknetObject()
      const controller = new Controller(this.network)

      await controller.mintOption(
        starknet.account as starknetjs.Account,
        optionToken,
        amount,
        events
      )
    },

    async closePosition(
      optionToken: string,
      amount: number,
      events: TransactionEvent
    ) {
      const starknet = await getStarknetObject()
      const controller = new Controller(this.network)

      await controller.close_position(
        starknet.account as starknetjs.Account,
        optionToken,
        amount,
        events
      )
    },

    async exercise(
      optionToken: string,
      amount: number,
      events: TransactionEvent
    ) {
      const starknet = await getStarknetObject()
      const controller = new Controller(this.network)

      await controller.exercise(
        starknet.account as starknetjs.Account,
        optionToken,
        amount,
        events
      )
    },

    async settle(
      writerOptionToken: string,
      amount: number,
      events: TransactionEvent
    ) {
      const starknet = await getStarknetObject()
      const controller = new Controller(this.network)

      await controller.settle(
        starknet.account as starknetjs.Account,
        writerOptionToken,
        amount,
        events
      )
    },

    async testTokenMint(
      tokenSymbol: MintableTestToken,
      amount: number,
      events: TransactionEvent
    ) {
      const starknet = await getStarknetObject()
      await mintTestToken(
        starknet.account as starknetjs.Account,
        starknet.provider,
        tokenSymbol,
        starknet.account.address,
        amount,
        events
      )
    },


    async getExpiryPrice(
      asset: ERC20,
      expiryTimestamp: number,
    ) {
      const starknet = await getStarknetObject()
      const oracle = new OracleContract(this.network)
      const expiryPrice = await oracle.getExpiryPrice(starknet.account as starknetjs.Account, asset, expiryTimestamp)
      return expiryPrice
    },


    async getOptionInfoByName(name: string) {
      const starknet = await getStarknetObject()
      const controller = new Controller(this.network)
      try {
        let instrument = await controller.getInstrument(starknet.account as starknetjs.Account, name)
        return instrument
      } catch (e) {
        console.error(e);
        return null
      }
    },
    async multicall(calldata: any, events: TransactionEvent) {
      const starknet = await getStarknetObject()
      const controller = new Controller(this.network)
      await controller.call(starknet.account as starknetjs.Account, calldata, events)
    }
  },
  persist: true,
})
