<script setup lang="ts">
import type { Position, Instrument, StarknetInstrument } from '@/env'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore, useStarknet, getGlobalAddressBook } from '@/stores'
import { getType, formatDate, USD, parseInstrumentName } from '@/utils'
import { ACTIONS, HISTORY_POSITION_STATUS } from '@/constants'
import { API } from '../api/index'
import { toastError, toastTxComplete, toastTxPending } from '../components/toasts'
import { ZERO } from '@/constants'
import type BigNumber from 'bignumber.js'

const route = useRoute()
const router = useRouter()
const store = useStore()
const starknet = useStarknet()

type Props = {
  title: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
})

// -1: init, 0: empty, 1: loading, 2: has data
const state = ref(-1)
const snDeliveryPrice = ref(0)
// @ts-ignore
const position = ref<Position>({})
const snInstrument = ref<StarknetInstrument>({})
const optionTokenBalance = ref<BigNumber>({})

const fetch = async (id: string) => {
  state.value = 1
  const res = await API().getPositionById(id)
  if (res && res.position_id) {
    state.value = 2
    if (Array.isArray(res.trade_list)) {
      res.trade_list.map((t) => {
        const action = ACTIONS[getType(t.side, res.type)]
        t.title = action.title
        t.funds = (t.side === 'ASK' ? '+' : '-') + parseFloat((Number(t.amount) * Number(t.price)).toPrecision(12))
        return t
      })
    }
    position.value = res

    // get starknet instrument
    if (store.type === 'starknet') {
      const instrument = await API().getInstrumentByName(position.value.instrument_name)
      if (!instrument.data) {
        return
      }
      snInstrument.value = instrument.data
      optionTokenBalance.value = await starknet.balanceof(snInstrument.value.option_token)
    }
  } else {
    state.value = 0
    // @ts-ignore
    position.value = {}
  }
}

const type = computed(() => {
  const { side, type } = position.value
  return getType(side, type)
})

const isHistory = computed(() => type.value === 'hc')

const status = computed(() => HISTORY_POSITION_STATUS[position.value.status])

const expired = computed(() => {
  const now = new Date()
  const date = new Date(position.value.expiration_date)
  return now > date ? 'Expired' : 'Hold'
})

const received = computed(() => {
  const { settlement_info, side, type: optionType, strike_price, size } = position.value
  let positionSize = Math.abs(size)

  if (store.type === 'starknet') {
    let deliveryPrice = snDeliveryPrice.value
    let strikePrice = Number(strike_price)

    if (optionType === 'PUT') {
      let priceUnit =
        deliveryPrice >= strikePrice
          ? side === 'ASK'
            ? strikePrice
            : 0 // not exercised
          : side === 'ASK'
          ? deliveryPrice
          : Number((strikePrice - deliveryPrice).toFixed(4)) // exercised
      return Number((priceUnit * positionSize).toFixed(4))
    } else {
      let price =
        deliveryPrice >= strikePrice
          ? side === 'ASK'
            ? deliveryPrice
            : Number((strikePrice - deliveryPrice).toFixed(4)) // exercised
          : side === 'ASK'
          ? strikePrice
          : 0 // not exercised
      return Number(((price * positionSize) / strikePrice).toFixed(4))
    }
  }

  if (settlement_info) {
    return side === 'ASK' ? settlement_info?.refund_margin : settlement_info?.bid_earnings
  }

  return 0
})

const deliveryPrice = computed<number>(() => {
  if (store.type === 'starknet') {
    return snDeliveryPrice.value
  }
  return position.value.settlement_info?.underlying_price || 0
})

const isStarknetClosePositionBtnVisible = computed(() => {
  return store.type === 'starknet' && position.value.size === 0
})

const isStarknetSettleBtnVisible = computed(() => {
  if (store.type !== 'starknet') {
    return false
  }
  const now = new Date()
  const date = new Date(position.value.expiration_date)
  let condition1 =  now > date

  let cd2 = position.value.side === 'ASK' && position.value.status !== 30
  return condition1 && cd2
})



const isStarknetExerciseBtnVisible = computed(() => {
  if (store.type !== 'starknet') {
    return false
  }
  const now = new Date()
  const date = new Date(position.value.expiration_date)
  let condition1 = now > date

  let cd2 = position.value.side === 'BID' && position.value.status !== 30

  let cd3 = false
  if (position.value.side === 'ASK') {
    if (optionTokenBalance.value.isGreaterThan(ZERO)) {
      cd3 = true
    }
  }
  return condition1 && (cd2 || cd3)
})

const starknetClosePosition = async () => {
  if (store.type !== 'starknet') {
    return
  }
  const instrument = await API().getInstrumentByName(position.value.instrument_name)
  if (!instrument.data) {
    return
  }
  const optionToken = instrument.data.option_token
  const writerToken = instrument.data.writer_token
  const optionTokenBalance = await starknet.balanceof(optionToken)
  const writerTokenBalance = await starknet.balanceof(writerToken)
  if (optionTokenBalance.isLessThanOrEqualTo(ZERO) || writerTokenBalance.isLessThanOrEqualTo(ZERO)) {
    toastError('insuffient option/writer token balance')
    return
  }

  const writerToOptionTokenAmt = writerTokenBalance.dividedBy(instrument.data.strike_price)
  let closeOptionTokenAmt = writerToOptionTokenAmt
  if (writerToOptionTokenAmt.isGreaterThan(optionTokenBalance)) {
    closeOptionTokenAmt = optionTokenBalance
  }
  if (instrument.code === 0) {
    await starknet.closePosition(instrument.data?.option_token, closeOptionTokenAmt.toNumber(), {
      onCompleted: (txHash: string) => {
        toastTxComplete(txHash)
      },
      onPending: (txHash: string) => {
        toastTxPending(txHash)
      },
      onError: (err) => {
        toastError(err)
        console.error(err)
      },
    })
  }
}

async function starknetExercise() {
  const instrument = await API().getInstrumentByName(position.value.instrument_name)
  if (!instrument.data) {
    return
  }

  const optionTokenBalance = await starknet.balanceof(instrument.data.option_token)
  if (optionTokenBalance.isLessThanOrEqualTo(ZERO)) {
    toastError('insuffient option token balance')
    return
  }
  await starknet.exercise(instrument.data.option_token, optionTokenBalance.toNumber(), {
    onCompleted: (txHash: string) => {
      toastTxComplete(txHash)
    },
    onPending: (txHash: string) => {
      toastTxPending(txHash)
    },
    onError: (err: any) => {
      toastError(err)
      console.error(err)
    },
  })
}

async function starknetSettle() {
  const instrument = await API().getInstrumentByName(position.value.instrument_name)
  if (!instrument.data) {
    return
  }

  const writerTokenBalance = await starknet.balanceof(instrument.data.writer_token)
  if (writerTokenBalance.isLessThanOrEqualTo(ZERO)) {
    toastError('insuffient writer token balance')
    return
  }

  await starknet.settle(instrument.data.writer_token, writerTokenBalance.toNumber(), {
    onCompleted: (txHash: string) => {
      toastTxComplete(txHash)
    },
    onPending: (txHash: string) => {
      toastTxPending(txHash)
    },
    onError: (err: any) => {
      toastError(err)
      console.error(err)
    },
  })
}

async function fetchExpiryPriceInOracle() {
  const addressbook = getGlobalAddressBook()
  const expiryPrice = await starknet.getExpiryPrice(addressbook.wbtc, position.value.expiration_timestamp)
  snDeliveryPrice.value = Number((expiryPrice.price / 100000000).toFixed(2))
}

onMounted(async () => {
  if (store.logged) {
    await fetch(route.params.id as string)
    await fetchExpiryPriceInOracle()
  }
})
</script>

<template>
  <div class="w-198 mx-auto pt-20">
    <SvgIcon
      name="back"
      width="40"
      height="40"
      class="text-neutral-310 cursor-pointer ml--16 mt-11.5 animated"
      un-hover="text-white"
      @click="router.back"
    />
    <h3 class="text-white font-800 text-4xl leading-11.25 pb-6 border-b border-neutral-800 mt-6">
      <span class="capitalize">{{ title }}</span>
    </h3>

    <div v-if="state < 2" class="flex flex-col items-center mt-14">
      <p v-if="state === 0" class="text-sm font-400 text-neutral-310 mt-15.5">You have no this position</p>
      <Loading v-if="state === 1" />
    </div>
    <div v-else class="flex flex-row flex-wrap justify-between mt-6 pb-42.5 space-x-8">
      <div
        class="space-y-4.5"
        :class="{
          'w-104': !isHistory,
          'w-full': isHistory,
        }"
      >
        <Card
          class="w-full"
          :item="position"
          :is-order="false"
          :type="type"
          :class="{
            '!h-60': isHistory,
          }"
          un-hover="!bg--neutral-1000 cursor-default"
        />

        <div
          class="flex flex-row bg--neutral-1000 px-7 pt-8 pb-8.5 rounded-5 border border-white border-opacity-20 box-border table w-full font-400"
        >
          <div class="table-header-group">
            <div class="table-row text-neutral-410 text-0.9375base leading-4.75">
              <div class="table-cell">Position History</div>
              <div class="table-cell">Size</div>
              <div class="table-cell text-right">Premium</div>
            </div>
          </div>
          <div class="table-row-group text-white text-base leading-5 space-y-4.5">
            <div v-for="trade in position.trade_list" :key="trade.trade_id" class="table-row">
              <div class="table-cell pt-5">
                <div>{{ formatDate(trade.created_at, 'yyyy/MM/dd') }}</div>
                <div class="text-xss leading-3.75 text-neutral-310">
                  {{ trade.title }}
                </div>
              </div>
              <div class="table-cell">{{ trade.amount }}</div>
              <div class="table-cell text-right">{{ trade.funds }}</div>
            </div>
          </div>
        </div>
        <!-- Settlement information -->
        <div
          v-if="(!isHistory && position.status === 30) || snDeliveryPrice > 0"
          class="flex flex-row bg--neutral-1000 px-7 pt-8 pb-8.5 rounded-5 border border-white border-opacity-20 box-border table w-full font-400"
        >
          <div class="table-header-group">
            <div class="table-row font-700 text-white text-4.75 leading-6">
              <div class="table-cell">Settlement information</div>
            </div>
          </div>
          <div class="table-row-group text-neutral-410 text-base leading-5 space-y-4.5">
            <div class="table-row">
              <div class="table-cell pt-5">
                <div>Delivery Price</div>
              </div>
              <div class="table-cell text-right text-white">
                {{ USD(deliveryPrice).format() }}
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell pt-5">
                <div>Receive</div>
              </div>
              <div class="table-cell text-right text-white">{{ received }} {{ position.quote_currency }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- position hold detail -->
      <div class="w-86" v-if="!isHistory">
        <ul
          class="min-w-82 mx-auto text-neutral-200 font-400 text-sm0.9 divide-y divide-opacity-43 divide-neutral-500 mt-2 mb-9.5"
        >
          <li class="flex justify-between px-1 py-4.5">
            <label>Contract Status</label>
            <span>{{ expired }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5">
            <label>Exercise status</label>
            <span>{{ status }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5" v-if="type === 'dh' || type === 'sp'">
            <label>Margin</label>
            <span>{{ position.margin }} {{ position.quote_currency }}</span>
          </li>
          <!-- <li class="flex justify-between px-1 py-4.5">
            <label>Price</label>
            <span>
              {{ formater.format(position.exercised_size * position.strike_price) }} /
              {{ position.base_currency }}
            </span>
          </li> -->
          <li class="flex justify-between px-1 py-4.5">
            <label>Settlement</label>
            <span>{{ position.quote_currency }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5">
            <label>Asset ID</label>
            <span>{{ position.position_num }}</span>
          </li>
        </ul>

        <!-- position actions button area -->
        <button
          v-if="isStarknetClosePositionBtnVisible && position.status !== 30"
          class="w-full h-16 bg-white rounded-3 text-lg font-800 text-neutral-1000 animated mb-4"
          un-hover="btn-hover"
          @click="starknetClosePosition"
        >
          Close Position
        </button>

        <button
          v-if="isStarknetSettleBtnVisible"
          class="w-full h-16 bg-white rounded-3 text-lg mb-4 font-800 text-neutral-1000 animated"
          un-hover="btn-hover"
          @click.stop="starknetSettle"
        >
          Settle
        </button>

        <button
          v-if="isStarknetExerciseBtnVisible && position.status !== 30"
          class="w-full h-16 bg-white rounded-3 text-lg font-800 text-neutral-1000 animated"
          un-hover="btn-hover"
          @click.stop="starknetExercise"
        >
          Exercise
        </button>
      </div>
    </div>
  </div>
</template>
