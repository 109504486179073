import { ZERO } from '@/constants'
import { getGlobalAddressBook, useStarknet } from '@/stores'
import {
  BnMul,
  newBnWithDecimals,
  parseInstrumentName,
  ERC20,
} from '@optiondance/starknet-sdk'
import type BigNumber from 'bignumber.js'
import { constants } from 'starknet'

export function getStarknetExplorerURL() {
  const starknet = useStarknet()
  return starknet.network === constants.NetworkName.SN_GOERLI
    ? 'https://testnet.starkscan.co'
    : 'https://starkscan.co'
}


export function snGetOrderApproveToken(
  optionType: string,
  side: string,
  optionTokenAddress: string
): ERC20 {
  const addressbook = getGlobalAddressBook()
  if (side === 'ASK') {
    return {
      id: optionTokenAddress,
      name: '',
      symbol: '',
      decimals: 8,
    }
  } else {
    if (optionType === 'PUT') {
      return {
        id: addressbook.usdc.id,
        name: addressbook.usdc.name,
        symbol: addressbook.usdc.symbol,
        decimals: addressbook.usdc.decimals,
      }
    } else {
      return {
        id: addressbook.wbtc.id,
        name: addressbook.wbtc.name,
        symbol: addressbook.wbtc.symbol,
        decimals: addressbook.wbtc.decimals,
      }
    }
  }
}


export function generateSnOrderSalt(): string {

  let result = '';

  for (let i = 0; i < 16; i++) {
      result += Math.floor(Math.random() * 10);
  }

  return result;

}


export function snGetOrderApproveAmount(
  inputAmount: number | string,
  orderprice: number,
  optionType: string,
  side: string,
  strikePrice: number,
  baseCurrency?: string
): BigNumber {
  const starknet = useStarknet()
  let approveAmount = ZERO
  if (side === 'ASK') {
    if (optionType === 'PUT') {
      //usdc
      approveAmount = newBnWithDecimals(Number(inputAmount) * strikePrice, 6)
    } else {
      approveAmount = newBnWithDecimals(inputAmount, baseCurrency === 'BTC' ? 8 : 18)
    }
  } else {
    if (optionType === 'PUT') {
      approveAmount = newBnWithDecimals(
        BnMul(inputAmount, orderprice),
        starknet.addressbook.usdc.decimals
      )
    } else {
      approveAmount = newBnWithDecimals(
        BnMul(inputAmount, orderprice),
        starknet.addressbook.wbtc.decimals
      )
    }
  }
  return approveAmount
}

export interface TransferInfo {
  token: ERC20
  amount: BigNumber
}

export function snGetMintOptionTransferInfo(
  instrumentName: string,
  inputAmount: number
): TransferInfo {
  const starknet = useStarknet()
  const instrument = parseInstrumentName(instrumentName, starknet.network)
  let transferInfo: TransferInfo
  if (instrument.optionType === 'PUT') {
    transferInfo = {
      token: starknet.addressbook.usdc as ERC20,
      amount: newBnWithDecimals(
        BnMul(inputAmount, instrument.strikePrice),
        starknet.addressbook.usdc.decimals
      ),
    }
  } else {
    transferInfo = {
      token: starknet.addressbook.wbtc as ERC20,
      amount: newBnWithDecimals(
        BnMul(inputAmount, instrument.strikePrice),
        starknet.addressbook.wbtc.decimals
      ),
    }
  }
  return transferInfo
}
