<!-- eslint-disable prettier/prettier -->
<script setup lang="ts">
import { toastError, toastTxComplete, toastTxPending } from '@/components/toasts'
import { useStarknet } from '@/stores'
import { computed, ref } from 'vue'


const starknet = useStarknet()

const mintToken = ref<string>('')
const mintAmount = ref<number>(0)

const mintBtnText = computed(() => {
  return `mint ${mintAmount.value} ${mintToken.value}`
})

const debugFillOrder = async () => {
  const messages = {
    buyOrderMessage: {
      message_prefix: '110930206544689809660069706067448260453',
      domain_prefix: {
        name: '24585265207132630178885165925',
        version: '1',
        chain_id: '32011625847331185079205521505',
      },
      sender: '0x176107b5fd4e783ce0d42797a2dc83f01741a912830bcf1e2a45175c41439e3',
      order: {
        base_asset: '0x109917d00b4c6ca64e5746951b89cb915089660ea60c5622c2dcd49008817f5',
        quote_asset: '0x0556101ad9e73a1cf84bba309ad2ada067dcbac5d287c87cd0a79d0a522aee58',
        side: '0',
        base_quantity: '100000000',
        price: {
          numerator: '3',
          denominator: '25',
        },
        expiration: '167238720000',
        salt: '167238720000',
      },
      sig_r: '1876834138570321420636147373526426115224432937287194110739236857431735773413',
      sig_s: '2203266929781319997935973727120858879164808399650911172433159238153541593196',
    },
    sellOrderMessage: {
      message_prefix: '110930206544689809660069706067448260453',
      domain_prefix: {
        name: '24585265207132630178885165925',
        version: '1',
        chain_id: '32011625847331185079205521505',
      },
      sender: '0x22033217a488511ab0deccab4a26c3ae28c49f0cd5fab38cf0425f272a74c58',
      order: {
        base_asset: '0x109917d00b4c6ca64e5746951b89cb915089660ea60c5622c2dcd49008817f5',
        quote_asset: '0x0556101ad9e73a1cf84bba309ad2ada067dcbac5d287c87cd0a79d0a522aee58',
        side: '1',
        base_quantity: '100000000',
        price: {
          numerator: '3',
          denominator: '25',
        },
        expiration: '167238720000',
        salt: '167238720000',
      },
      sig_r: '1617640536221134868931229076405445615632979315335175749697410959929431227500',
      sig_s: '2162497251548950875073846989377566458165937197393648917945332733313850310806',
    },
    price: {
      numerator: '3',
      denominator: '25',
    },
    fillQuantity: '100000000',
  }

  await starknet.fillOrder(messages.buyOrderMessage, messages.sellOrderMessage, messages.price, messages.fillQuantity, {
    onCompleted: (txHash: string) => {
      toastTxComplete(txHash)
    },
    onPending: (txHash: string) => {
      toastTxPending(txHash)
    },
    onError: (err) => {
      toastError(err)
      console.error(err)
    },
  })
}
</script>

<template>
  <div class="relative h-screen w-screen flex justify-center action-screen scroll-container overflow-y-auto">
    <div class="absolute top-33 flex flex-col items-center justify-start mx-auto">
      <h3 class="text-2.5xl font-900 text-white uppercase tracking-0.2em">Starknet Debug</h3>
      <button
        type="button"
        class="w-full h-16 capitalize tracking-0.01em bg-white rounded-3 font-800 text-lg text-neutral-1000 relative flex items-center justify-center"
        style="box-shadow: 0px 4px 36px #17171c"
        un-hover="btn-hover"
        @click="debugFillOrder"
      >
        DebugfillOrder
      </button>
    </div>
  </div>
</template>

<style scoped>
.action-screen::before {
  background: linear-gradient(180deg, #0977c3 0%, rgba(9, 119, 195, 0) 100%);
}
input {
  border: none;
  height: 35px;
  display: inline-block;
  margin: 8px;
  padding: 8px;
  background: transparent;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  border-radius: 0.3rem;
}
.create-instrument-form input {
  color: white;
}
.create-instrument-form span {
  color: white;
  margin: 8px;
  display: inline-block;
}

.create-instrument-form button {
  /* display: block;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  color: black; */
  background-color: white;
  padding: 4px 16px;
  margin: 16px 0 48px 0;
}

.vs__selected {
  color: white;
}
</style>
