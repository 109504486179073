import {} from '@optiondance/starknet-sdk'
import { useStarknet } from '@/stores'


export async function connectStarknet(
  callback: (type: string, code: string, step: number, account: any) => void, walletId?: string
) {
  const starknet = useStarknet()
  return starknet.connect(callback, walletId)
}
