<script setup lang="ts">
import type { Amount } from '@/env'
import { computed, ref, onBeforeMount, onMounted } from 'vue'
import { nanoid } from 'nanoid'
import { getGlobalAddressBook, useSearchStore, useStarknet } from '@/stores'
import { leftSettlementPeriod, USD } from '@/utils'
import api from '@/api'

import OrderDetailsCard from '@/components/OrderDetailsCard.vue'
import { API } from '../../api/index'
import vSelect from 'vue-select'
import { toastTxComplete, toastTxPending, toastError } from '@/components/toasts'
import { StarknetNetwork, getAddressBook } from '@optiondance/starknet-sdk'

const starknet = useStarknet()
const instruments = ref<unknown[]>([])

const underlyingAsset = ref<string>('BTC')
const quoteAsset = ref<string>('USDC')
const strikePrice = ref<string>('')
const expiry = ref<string>('')
const optionType = ref<string>('PUT')

const fetch = async (page = 1, limit = 50) => {
  try {
    const res = await API().listInstruments({
      status: 'open',
      current: page,
      size: limit,
    })
    instruments.value = res.records
  } catch (err) {
    console.log(err)
  }
}

const createInstrument = async () => {
  await starknet.createInstrument(
    underlyingAsset.value,
    quoteAsset.value,
    Number(strikePrice.value) * 100000000,
    optionType.value,
    expiry.value,
    {
      onCompleted: (txHash: string) => {
        toastTxComplete(txHash)
      },
      onPending: (txHash: string) => {
        toastTxPending(txHash)
      },
      onError: (err) => {
        toastError(err)
        console.error(err)
      },
    },
  )
}

onMounted(() => {
  let addressbook = getGlobalAddressBook()
  console.log(addressbook)
  fetch()
})
</script>

<template>
  <div class="relative h-screen w-screen flex justify-center action-screen scroll-container overflow-y-auto">
    <div class="absolute top-33 flex flex-col items-center justify-start mx-auto">
      <h3 class="text-2.5xl font-900 text-white uppercase tracking-0.2em">Create / View Starknet Instruments</h3>
      <p class="text-lg0.8214 font-400 text-white text-opacity-30 mt-4">create instruments</p>
      <form class="create-instrument-form">
        <span>underlyingAsset</span>
        <vSelect :options="['BTC', 'ETH']" v-model="underlyingAsset" />
        <span>quoteAsset</span>
        <vSelect :options="['USDC']" v-model="quoteAsset" />
        <span>optionType</span>
        <vSelect :options="['PUT', 'CALL']" v-model="optionType" class="mb-4" />
        <span>strikePrice</span>
        <input v-model="strikePrice" />
        <span>expiry</span>
        <input v-model="expiry" type="date" />
        <button
          type="button"
          class="w-full h-16 capitalize tracking-0.01em bg-white rounded-3 font-800 text-lg text-neutral-1000 relative flex items-center justify-center"
          style="box-shadow: 0px 4px 36px #17171c"
          un-hover="btn-hover"
          @click="createInstrument"
        >
          Create
        </button>
      </form>

      <p class="text-lg0.8214 font-400 text-white text-opacity-30 mt-4">view all created instruments</p>
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="border-b">
                  <tr>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">#</th>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">name</th>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">option token</th>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">writer token</th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="border-b" v-for="i in instruments" :key="i">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {{ i.id }}
                    </td>
                    <td class="text-sm text-white font-light px-6 py-4 whitespace-nowrap">
                      {{ i.name }}
                    </td>
                    <td class="text-sm text-white font-light px-6 py-4 whitespace-nowrap">
                      {{ i.option_token }}
                    </td>
                    <td class="text-sm text-white font-light px-6 py-4 whitespace-nowrap">
                      {{ i.writer_token }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-screen::before {
  background: linear-gradient(180deg, #0977c3 0%, rgba(9, 119, 195, 0) 100%);
}
input {
  border: none;
  height: 35px;
  display: inline-block;
  margin: 8px;
  padding: 8px;
  background: transparent;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  border-radius: 0.3rem;
}
.create-instrument-form input {
  color: white;
}
.create-instrument-form span {
  color: white;
  margin: 8px;
  display: inline-block;
}

.create-instrument-form button {
  /* display: block;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  color: black; */
  background-color: white;
  padding: 4px 16px;
  margin: 16px 0 48px 0;
}

.vs__selected {
  color: white;
}
</style>
