<script setup lang="ts">
import type { Amount } from '@/env'
import { computed, ref, onBeforeMount, onMounted } from 'vue'
import { nanoid } from 'nanoid'
import { getGlobalAddressBook, useSearchStore, useStarknet } from '@/stores'
import { leftSettlementPeriod, USD } from '@/utils'
import api from '@/api'

import OrderDetailsCard from '@/components/OrderDetailsCard.vue'
import { API } from '../../api/index'
import vSelect from 'vue-select'
import { toastTxComplete, toastTxPending, toastError } from '@/components/toasts'
import  { newBnWithDecimals, MintableTestToken } from '@optiondance/starknet-sdk'

const starknet = useStarknet()

const mintToken = ref<string>('')
const mintAmount = ref<number>(0)
const usdcBalance = ref<number>(0)
const wbtcBalance = ref<number>(0)

const mintBtnText = computed(() => {
  return `mint ${mintAmount.value} ${mintToken.value}`
})

const callMintTestToken = async () => {
  const token: MintableTestToken = mintToken.value === 'USDC' ? 'USDC' : 'WBTC'
  const decimals = token === 'USDC' ? 6 : 8
  const amount = newBnWithDecimals(mintAmount.value, decimals)
  await starknet.testTokenMint(token, amount.toNumber(), {
    onCompleted: (txHash: string) => {
      toastTxComplete(txHash)
    },
    onPending: (txHash: string) => {
      toastTxPending(txHash)
    },
    onError: (err) => {
      toastError(err)
      console.error(err)
    },
  })
}


onMounted(async () => {
  let addressbook = getGlobalAddressBook()
  usdcBalance.value = ((await starknet.balanceof(addressbook.usdc.id))).toNumber()
  wbtcBalance.value = ((await starknet.balanceof(addressbook.wbtc.id))).toNumber()
})

</script>

<template>
  <div class="relative h-screen w-screen flex justify-center action-screen scroll-container overflow-y-auto">
    <div class="absolute top-33 flex flex-col items-center justify-start mx-auto">

      <h3 class="text-2.5xl font-900 text-white uppercase tracking-0.2em">Starknet Test tokens Faucet</h3>
      <p class="text-lg0.8214 font-400 text-white text-opacity-30 mt-4">Mint Test tokens</p>
      <p class="text-lg0.8214 font-400 text-white text-opacity-30 mt-4">Test tokens Balance, WBTC: {{usdcBalance}} USDC: {{usdcBalance}} </p>



      <form class="create-instrument-form">
        <span>mintToken</span>
        <vSelect :options="['WBTC', 'USDC']" v-model="mintToken" class="mb-8" />
        <span>mintAmount</span>
        <input v-model="mintAmount" />
        <button
          type="button"
          class="w-full h-16 capitalize tracking-0.01em bg-white rounded-3 font-800 text-lg text-neutral-1000 relative flex items-center justify-center"
          style="box-shadow: 0px 4px 36px #17171c"
          un-hover="btn-hover"
          @click="callMintTestToken"
        >
          {{ mintBtnText }}
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped>
.action-screen::before {
  background: linear-gradient(180deg, #0977c3 0%, rgba(9, 119, 195, 0) 100%);
}
input {
  border: none;
  height: 35px;
  display: inline-block;
  margin: 8px;
  padding: 8px;
  background: transparent;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  border-radius: 0.3rem;
}
.create-instrument-form input {
  color: white;
}
.create-instrument-form span {
  color: white;
  margin: 8px;
  display: inline-block;
}

.create-instrument-form button {
  /* display: block;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  color: black; */
  background-color: white;
  padding: 4px 16px;
  margin: 16px 0 48px 0;
}

.vs__selected {
  color: white;
}
</style>
