<script setup lang="ts">
import type { Amount } from '@/env'
import { computed, ref, onBeforeMount, onMounted } from 'vue'
import { nanoid } from 'nanoid'
import { useSearchStore, useStarknet } from '@/stores'
import { leftSettlementPeriod, USD } from '@/utils'
import api from '@/api'

import OrderDetailsCard from '@/components/OrderDetailsCard.vue'
import { API } from '../../api/index'
import vSelect from 'vue-select'
import { toastTxComplete, toastTxPending, toastError } from '@/components/toasts'

const starknet = useStarknet()
const expiryPrices = ref<unknown[]>([])

const underlyingAsset = ref<string>('BTC')

const fetch = async (page = 1, limit = 50) => {
  try {
    const res = await API().listDeliveryPrices({
      current: page,
      size: limit,
      asset: underlyingAsset.value
    })
    expiryPrices.value = res.records
  } catch (err) {
    console.log(err)
  }
}

onMounted(() => {
  fetch()
})
</script>

<template>
  <div class="relative h-screen w-screen flex justify-center action-screen scroll-container overflow-y-auto">
    <div class="absolute top-33 flex flex-col items-center justify-start mx-auto">
      <h3 class="text-2.5xl font-900 text-white uppercase tracking-0.2em">View Oracle Expiry Price History</h3>
      <form class="create-instrument-form">
        <span>underlyingAsset</span>
        <vSelect :options="['BTC', 'ETH']" v-model="underlyingAsset" @change="fetch()"/>
      </form>
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="border-b">
                  <tr>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">#</th>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">asset</th>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">expiryTime</th>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">Price</th>
                    <th scope="col" class="text-sm font-medium text-white px-6 py-4 text-left">Price Commit At</th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="border-b" v-for="i in expiryPrices" :key="i">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                      {{ i.id }}
                    </td>
                    <td class="text-sm text-white font-light px-6 py-4 whitespace-nowrap">
                      {{ underlyingAsset }}
                    </td>
                    <td class="text-sm text-white font-light px-6 py-4 whitespace-nowrap">
                      {{ i.expiry_time }}
                    </td>
                    <td class="text-sm text-white font-light px-6 py-4 whitespace-nowrap">
                      {{ i.price / 100000000 }}
                    </td>
                    <td class="text-sm text-white font-light px-6 py-4 whitespace-nowrap">
                      {{ i.price_time }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.action-screen::before {
  background: linear-gradient(180deg, #0977c3 0%, rgba(9, 119, 195, 0) 100%);
}
input {
  border: none;
  height: 35px;
  display: inline-block;
  margin: 8px;
  padding: 8px;
  background: transparent;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  border-radius: 0.3rem;
}
.create-instrument-form input {
  color: white;
}
.create-instrument-form span {
  color: white;
  margin: 8px;
  display: inline-block;
}

.create-instrument-form button {
  /* display: block;
  border: rgba(60, 60, 60, 0.26) 1px solid;
  color: black; */
  background-color: white;
  padding: 4px 16px;
  margin: 16px 0 48px 0;
}

.vs__selected {
  color: white;
}
</style>
