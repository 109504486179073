import ErrorIcon from '@/components/toasts/ErrorIcon.vue'
import ErrorToast from '@/components/toasts/ErrorToast.vue'
import CloseIcon from '@/components/toasts/CloseIcon.vue'
import SuccessToast from '@/components/toasts/SuccessToast.vue'
import TransactionToast from '@/components/toasts/TransactionToast.vue'
import SuccessIcon from '@/components/toasts/SuccessIcon.vue'
import LoadingIcon from '@/components/toasts/LoadingIcon.vue'
import { POSITION, useToast } from 'vue-toastification'

const toast = useToast()
const txToastMap = new Map()

export function toastTxPending(txHash: string) {
  const id = toast(
    {
      type: 'success',
      component: TransactionToast,
      props: {
        text: 'Your Transaction has been broadcasted',
        id: txHash,
      },
    },
    {
      timeout: false,
      icon: LoadingIcon,
      closeButton: CloseIcon,
      hideProgressBar: true,
      closeOnClick: false,
    }
  )
  txToastMap.set(txHash, id)
}

export function toastTxComplete(txHash: string) {
  const toastID = txToastMap.get(txHash)
  toast.update(toastID, {
    content: {
      type: 'success',
      component: TransactionToast,
      props: {
        text: 'Your Transaction has been Confirmed ',
        id: txHash,
      },
    },
    options: {
      timeout: 5000,
      icon: SuccessIcon,
      closeButton: CloseIcon,
      hideProgressBar: true,
      closeOnClick: false,
    },
  })
}

export function toastError(text: string) {
  toast.error(
    {
      type: 'error',
      component: ErrorToast,
      props: {
        text: text,
      },
    },
    {
      timeout: false,
      icon: ErrorIcon,
      closeButton: CloseIcon,
      position: POSITION.TOP_CENTER,
      hideProgressBar: true,
    }
  )
}

export function toastSuccess(text: string, id: string) {
  toast(
    {
      type: 'success',
      component: SuccessToast,
      props: {
        text: text,
        id: id,
      },
    },
    {
      timeout: 10000,
      icon: SuccessIcon,
      closeButton: CloseIcon,
      hideProgressBar: true,
    }
  )
}
