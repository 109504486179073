import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import PositionsView from '@/views/PositionsView.vue'
import Open from '@/views/positions/Open.vue'
import Hold from '@/views/positions/Hold.vue'
import History from '@/views/positions/History.vue'
import Orders from '@/views/positions/history/Orders.vue'
import Options from '@/views/positions/history/Options.vue'
import OpenOrder from '@/views/OpenOrder.vue'
import HoldPosition from '@/views/HoldPosition.vue'
import Order from '@/views/Order.vue'
import Position from '@/views/Position.vue'

import DipHunter from '@/views/DipHunter.vue'
import DipHunterNormal from '@/views/DipHunterNormal.vue'
import DipHunterList from '@/views/DipHunterList.vue'

import StableProfit from '@/views/StableProfit.vue'
import StableProfitNormal from '@/views/StableProfitNormal.vue'
import StableProfitList from '@/views/StableProfitList.vue'

import CrashProtection from '@/views/CrashProtection.vue'
import CrashProtectionNormal from '@/views/CrashProtectionNormal.vue'

import BullishProtection from '@/views/BullishProtection.vue'
import BullishProtectionNormal from '@/views/BullishProtectionNormal.vue'

import StarknetController from '@/views/starknet/Controller.vue'
import StarknetFaucet from '@/views/starknet/Faucet.vue'
import StarknetDebug from '@/views/starknet/Debug.vue'
import StarknetOracle from '@/views/starknet/Oracle.vue'

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeView,
    },
    {
      path: '/positions',
      name: 'Positions',
      component: PositionsView,
      children: [
        {
          path: '',
          name: 'Open',
          meta: {
            scrollTop: 0,
          },
          alias: ['/positions/open'],
          component: Open,
        },
        {
          path: 'hold',
          name: 'Hold',
          meta: {
            scrollTop: 0,
          },
          component: Hold,
        },
        {
          path: 'history',
          name: 'History',
          meta: {
            last: 'Orders',
          },
          component: History,
          children: [
            {
              path: '',
              name: 'Orders',
              meta: {
                scrollTop: 0,
              },
              alias: ['/positions/history/orders'],
              component: Orders,
            },
            {
              path: 'options',
              name: 'Options',
              meta: {
                scrollTop: 0,
              },
              component: Options,
            },
          ],
        },
      ],
    },
    {
      path: '/positions/open/:id',
      name: 'OpenOrder',
      component: OpenOrder,
    },
    {
      path: '/positions/hold/:id',
      name: 'HoldPosition',
      component: Position,
      props: { title: 'Hold' }
    },
    {
      path: '/positions/history/orders/:id',
      name: 'Order',
      component: Order,
    },
    {
      path: '/positions/history/options/:id',
      name: 'Position',
      component: Position,
      props: { title: 'History' }
    },
    {
      path: '/dip-hunter',
      name: 'DipHunter',
      component: DipHunter,
      children: [
        {
          path: '',
          name: 'DipHunterNormal',
          component: DipHunterNormal,
        },
        {
          path: 'list',
          name: 'DipHunterList',
          component: DipHunterList,
        },
      ],
    },
    {
      path: '/stable-profit',
      name: 'StableProfit',
      component: StableProfit,
      children: [
        {
          path: '',
          name: 'StableProfitNormal',
          component: StableProfitNormal,
        },
        {
          path: 'list',
          name: 'StableProfitList',
          component: StableProfitList,
        },
      ],
    },
    {
      path: '/crash-protection',
      name: 'CrashProtection',
      component: CrashProtection,
      children: [
        {
          path: '',
          name: 'CrashProtectionNormal',
          component: CrashProtectionNormal,
        },
      ],
    },
    {
      path: '/bullish-protection',
      name: 'BullishProtection',
      component: BullishProtection,
      children: [
        {
          path: '',
          name: 'BullishProtectionNormal',
          component: BullishProtectionNormal,
        },
      ],
    },
    {
      path: '/crash-protection',
      name: 'CrashProtection',
      component: CrashProtection,
      children: [
        {
          path: '',
          name: 'CrashProtectionNormal',
          component: CrashProtectionNormal,
        },
      ],
    },
    {
      path: '/bullish-protection',
      name: 'BullishProtection',
      component: BullishProtection,
      children: [
        {
          path: '',
          name: 'BullishProtectionNormal',
          component: BullishProtectionNormal,
        },
      ],
    },
    {
      path: '/starknet/controller',
      name: 'StarknetController',
      component: StarknetController,
    },
    {
      path: '/starknet/faucet',
      name: 'StarknetFaucet',
      component: StarknetFaucet,
    },
    {
      path: '/starknet/debug',
      name: 'StarknetDebug',
      component: StarknetDebug,
    },
    {
      path: '/starknet/oracle',
      name: 'StarknetOracle',
      component: StarknetOracle,
    },
    {
      path: '/:path(.*)',
      redirect: '/',
    },
  ],
})

router.beforeEach(async (_to, from, next) => {
  if (from.path.startsWith('/positions')) {
    const route = from.matched[from.matched.length - 1]
    if (route && route.meta && 'scrollTop' in route.meta) {
      route.meta.scrollTop =
        document.getElementById('scroll-container')?.scrollTop || 0
    }
  }
  next()
})

export default router
