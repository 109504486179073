import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import 'virtual:svg-icons-register'

import 'uno.css'
import '@unocss/reset/tailwind.css'
import '@fontsource/mulish/200.css'
import '@fontsource/mulish/300.css'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/500.css'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/800.css'
import '@fontsource/mulish/900.css'
import 'vue-select/dist/vue-select.css'


import router from './router'
import App from './App.vue'
import Card from '@/components/Card.vue'
import Loading from '@/components/Loading.vue'
import SvgIconVue from '@/components/SvgIcon.vue'
import Toast from 'vue-toastification'
import '@/components/toasts/Toast.scss'
import MVM from '@foxone/mvm'


const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

export const app = createApp(App)

app.use(pinia)
  .use(router)
  .use(Toast, {
    shareAppContext: true,
    onMounted: (_: any, toastApp: any) => {
      toastApp.use(router)
    },
  })
  .component('Card', Card)
  .component('Loading', Loading)
  .component('SvgIcon', SvgIconVue)
  .mount('#app')


app.config.globalProperties.$mvm = new MVM({
  infuraId: ""
});