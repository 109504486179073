import type { Resp, StarknetInstrument } from '@/env'
import { useStore } from '@/stores'
import type { AxiosInstance } from 'axios'
import axios from 'axios'
export interface PageOrderParams {
  size: number
  current: number
  order?: 'asc' | 'desc'
}

export interface MarketFilterParams {
  baseCurrency: string
  quoteCurrency: string
  optionType: string
  deliveryType?: string
  strikePrice?: string
  side?: string
}


export type ListDeliveryPricesParams = PageOrderParams | { asset: string }

export function toMarketFilterParams(
  params: URLSearchParams
): MarketFilterParams {
  const ret: MarketFilterParams = {
    baseCurrency: params.get('baseCurrency') ?? '',
    quoteCurrency: params.get('quoteCurrency') ?? '',
    optionType: params.get('optionType') ?? '',
  }
  if (params.get('deliveryType')) {
    ret.deliveryType = params.get('deliveryType') ?? ''
  }
  if (params.get('strikePrice')) {
    ret.strikePrice = params.get('strikePrice') ?? ''
  }
  if (params.get('side')) {
    ret.side = params.get('side') ?? ''
  }
  return ret
}

export interface StarknetCreateOrderReq {
  order: Order
  sender: string
  orderHash: string
  sig_r: string
  sig_s: string
}

export interface MixinCreateOrderReq {
  side: string
  type: string
  price: string
  amount: string
  instrument_name: string
}

export interface Order {
  user?: string
  instrument: string
  quantity: number
  price: number
  side: string
  type: string
  expiration: number
  salt: string
}

export type listParams = { status: string } & PageOrderParams

export interface BaseApi {
  // market
  listStrikePrices(params: MarketFilterParams): any
  listMarketByStrikePrice(price: number, params: MarketFilterParams): any
  //orders
  listOrders(params: listParams): any
  getOrderById(id: string): any
  createOrder(req: StarknetCreateOrderReq | MixinCreateOrderReq): any
  cancelOrder(id: string): any
  //positions
  listPositions(params: listParams): any
  getPositionById(id: string): any
  listInstruments(params: listParams): any
  getInstrumentByName(name: string): Promise<Resp<StarknetInstrument | null>>
  listDeliveryPrices(params: ListDeliveryPricesParams): any
}

export function API(): BaseApi {
  const store = useStore()
  if (store.type === 'starknet') {
    return new OptionStarknetApi()
  }
  return new OptionMixinApi()
}

export class OptionStarknetApi implements BaseApi {
  private baseUrl: string
  private client: AxiosInstance

  constructor() {
    this.baseUrl = import.meta.env.VITE_STARTNET_API
    this.client = axios.create({
      baseURL: this.baseUrl,
    })
  }
  async listStrikePrices(params: MarketFilterParams) {
    return await this.client.get('/api/v1/market/strike-prices', {
      params: params,
    })
  }

  async listMarketByStrikePrice(price: number, params: MarketFilterParams) {
    let resp = await this.client.get(`/api/v1/market/price/${price}`, {
      params: params,
    })
    if (!resp.data) return []
    return resp.data.map((e) => ({
      asks: e.asks.map((entry: any) => {
        return {
          price: entry.price,
          amount: entry.amount,
        }
      }),
      bids: e.bids.map((entry: any) => {
        return {
          price: entry.price,
          amount: entry.size,
        }
      }),
      expiration_date: e.expiration,
      expiration_timestamp: Math.floor(new Date(e.expiration).getTime() / 1000),
      instrument_name: e.instrumentName,
      instrument: e.instrument,
      option_type: e.optionType,
      strike_price: e.strikePrice,
    }))
  }

  async listOrders(params: listParams) {
    const store = useStore()
    if (!store.user) {
      console.error('wallet user not found')
      return
    }
    const userId = store.user.user_id
    const resp = await this.client.get('/api/v1/order', {
      params: {
        ...params,
        userId: userId,
      },
    })
    return resp.data
  }
  async getOrderById(id: string) {
    const resp = await this.client.get('/api/v1/order/' + id)
    return resp.data.data
  }

  async createOrder(req: StarknetCreateOrderReq | MixinCreateOrderReq) {
    return await this.client.post('/api/v1/order/', req)
  }

  async cancelOrder(id: string) {
    const resp = await this.client.post(`/api/v1/order/${id}/cancel`)
    return resp.data
  }

  async listPositions(params: listParams) {
    const store = useStore()
    if (!store.user) {
      console.error('wallet user not found')
      return
    }
    const userId = store.user.user_id
    const resp = await this.client.get('/api/v1/position', {
      params: {
        ...params,
        userId: userId,
      },
    })
    return resp.data
  }
  async getPositionById(id: string) {
    const resp = await this.client.get('/api/v1/position/' + id)
    return resp.data.data
  }

  async listInstruments(params: PageOrderParams) {
    const resp = await this.client.get('/api/v1/instrument', {
      params: params,
    })
    return resp.data.data
  }

  async getInstrumentByName(name: string): Promise<Resp<StarknetInstrument>> {
    const resp = await this.client.get('/api/v1/instrument/' + name)
    return resp.data
  }

  async listDeliveryPrices(params: ListDeliveryPricesParams) {
    const resp = await this.client.get('/api/v1/expiry-price', {
      params: params,
    })
    return resp.data.data
  }
}

export class OptionMixinApi implements BaseApi {
  private baseUrl: string
  private client: AxiosInstance

  constructor() {
    this.baseUrl = import.meta.env.VITE_API_HOST
    const store = useStore()
    this.client = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Authorization: `Bearer ${store.token}`,
      },
    })
  }
  async listStrikePrices(params: MarketFilterParams) {
    const resp = await this.client.get('/api/v1/market/strike-prices', {
      params: params,
    })
    return resp.data
  }

  async listMarketByStrikePrice(price: number, params: MarketFilterParams) {
    const resp = await this.client.get(`/api/v1/market/price/${price}`, {
      params: params,
    })
    return resp.data
  }

  async listOrders(params: listParams) {
    const resp = await this.client.get('/api/v1/orders', {
      params: params,
    })
    return resp.data
  }

  async getOrderById(id: string) {
    return await this.client.get('/api/v1/order/' + id)
  }

  async createOrder(req: StarknetCreateOrderReq | MixinCreateOrderReq) {
    return await this.client.post('/api/v1/order/', req)
  }

  async cancelOrder(id: string) {
    const resp = await this.client.post(`/api/v1/order/${id}/cancel`)
    return resp.data
  }

  async listPositions(params: listParams) {
    const resp = await this.client.get('/api/v1/positions', {
      params: params,
    })
    return resp.data
  }
  async getPositionById(id: string) {
    const resp = await this.client.get('/api/v1/position/' + id)
    return resp.data.data
  }

  async listInstruments(params: PageOrderParams) {
    console.log(params)
    return []
  }

  async getInstrumentByName(
    name: string
  ): Promise<Resp<StarknetInstrument | null>> {
    return { code: 0, msg: '', data: null }
  }

  async listDeliveryPrices(params: ListDeliveryPricesParams) {
    const resp = await this.client.get('/api/v1/expiry-price', {
      params: params,
    })
    return resp.data.data
  }
}
