<script setup lang="ts">
import type { Order } from '@/env'
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores'
import { getType, formatDate, USD } from '@/utils'
import { ACTIONS, OPEN_ORDER_STATUS } from '@/constants'
import { API } from '../api/index'

const route = useRoute()
const router = useRouter()
const store = useStore()

// -1: init, 0: empty, 1: loading, 2: has data, 3: cancel
const state = ref(-1)
// @ts-ignore
const item = ref<Order>({})

const fetch = async (id: string) => {
  state.value = 1
  const res = await API().getOrderById(id)
  if (res && res.order_id) {
    state.value = 2
    if (Array.isArray(res.trade_list)) {
      res.trade_list.map((t) => {
        const action = ACTIONS[getType(t.side, res.option_type)]
        t.title = action.title
        t.funds = (t.side === 'ASK' ? '+' : '-') + parseFloat((Number(t.amount) * Number(t.price)).toPrecision(5))
        return t
      })
    }
    item.value = res
  } else {
    state.value = 0
    // @ts-ignore
    item.value = {}
  }
}

const type = computed(() => {
  const { side, option_type } = item.value
  return getType(side, option_type)
})

const isHC = computed(() => type.value === 'hc')
const cancelOrderButtonVisible = computed(() => {
  if (store.type === 'starknet') {
    return String(item.value.order_status) === 'open'
  }
  return item.value.order_status < 30
})

const status = computed(() => {
  if (store.type === 'starknet') {
    return item.value.order_status
  }
  return OPEN_ORDER_STATUS[item.value.order_status]
})

const cancel = async () => {
  state.value = 3
  try {
    const res = await API().cancelOrder(item.value.order_id)
    if (store.type === 'starknet') {
      if (res && res.id) {
        item.value.order_status = 'Cancel'
      }
    } else if (res && Object.keys(res).length === 0) {
      item.value.order_status = 40
    }
  } catch (err) {
    state.value = 2
    console.log(err)
  }
}

onMounted(() => {
  if (store.logged) {
    fetch(route.params.id as string)
  }
})
</script>

<template>
  <div class="w-198 mx-auto pt-20">
    <SvgIcon
      name="back"
      width="40"
      height="40"
      class="text-neutral-310 cursor-pointer ml--16 mt-11.5 animated"
      un-hover="text-white"
      @click="router.back"
    />
    <h3 class="text-white font-800 text-4xl leading-11.25 pb-6 border-b border-neutral-800 mt-6">
      <span class="capitalize">Order</span>
    </h3>

    <div v-if="state < 2" class="flex flex-col items-center mt-14">
      <p v-if="state === 0" class="text-sm font-400 text-neutral-310 mt-15.5">You have no this order</p>
      <Loading v-if="state === 1" />
    </div>
    <div class="flex flex-row flex-wrap justify-between mt-6 pb-42.5 space-x-8" v-else>
      <div
        class="space-y-4.5"
        :class="{
          'w-104': !isHC,
          'w-full': isHC,
        }"
      >
        <Card
          :item="item"
          :is-order="true"
          :type="type"
          class="w-full"
          :class="{
            '!h-60': isHC,
          }"
          un-hover="!bg--neutral-1000 cursor-default"
        />

        <div
          class="flex flex-row bg--neutral-1000 px-7 pt-8 pb-8.5 rounded-5 border border-white border-opacity-20 box-border table w-full font-400"
        >
          <div class="table-header-group">
            <div class="table-row text-neutral-410 text-0.9375base leading-4.75">
              <div class="table-cell">Date</div>
              <div class="table-cell">Size</div>
              <div class="table-cell text-right">Premium</div>
            </div>
          </div>
          <div class="table-row-group text-white text-base leading-5 space-y-4.5">
            <div class="table-row" v-for="trade in item.trade_list" :key="trade.trade_id">
              <div class="table-cell pt-5">
                <div>{{ formatDate(trade.created_at, 'yyyy/MM/dd') }}</div>
                <div class="text-xss leading-3.75 text-neutral-310">
                  {{ trade.title }}
                </div>
              </div>
              <div class="table-cell">{{ trade.amount }}</div>
              <div class="table-cell text-right">{{ trade.funds }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-86" v-if="!isHC">
        <ul
          class="min-w-82 mx-auto text-neutral-200 font-400 text-sm0.9 divide-y divide-opacity-43 divide-neutral-500 mt-2 mb-9.5"
        >
          <li class="flex justify-between px-1 py-4.5">
            <label>Order status</label>
            <span>{{ status }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5" v-if="type === 'dh' || type === 'sp'">
            <label>Margin</label>
            <span>{{ item.margin }} {{ item.quote_currency }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5">
            <label>Price</label>
            <span v-if="item.option_type === 'PUT'"> {{ USD(item.price).format() }} / {{ item.base_currency }} </span>
            <span v-else>{{ item.price }} {{ item.base_currency }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5">
            <label>Settlement</label>
            <span>{{ item.quote_currency }}</span>
          </li>
          <li class="flex justify-between px-1 py-4.5">
            <label>Order ID</label>
            <span>{{ item.order_num }}</span>
          </li>
        </ul>

        <button
          v-if="cancelOrderButtonVisible"
          :disabled="state === 3"
          class="w-full h-16 bg-white rounded-3 text-lg font-800 text-neutral-1000 animated"
          un-hover="btn-hover"
          @click.stop="cancel"
        >
          Cancel order
        </button>
      </div>
    </div>
  </div>
</template>
