<template>
  <div class="ml-4.5">
    <h5 class="text-white font-500 text-lg leading-5.75 mb-2.5">
      {{ text }}
    </h5>
    <a
      class="text-base font-600 leading-5"
      style="color: #0a84ff"
      target="_blank"
      :href="txUrl"
    >
      View details
    </a>
  </div>
</template>

<script setup lang="ts">
import { getStarknetExplorerURL } from '@/utils/starknet'
import { computed } from '@vue/runtime-core'

interface Props {
  text: string
  id: string
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  id: '',
})

const txUrl = computed(() => {
  return `${getStarknetExplorerURL()}/tx/${props.id}`
})
</script>
