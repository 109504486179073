<template>
  <div
    id="account"
    class="px-5 pt-10 pb-6 box-border w-103 bg-neutral-1001 rounded-2.5xl absolute inset-border select-none modal-container"
  >
    <SvgIcon
      name="close"
      class="absolute top-10 right-7 cursor-pointer text-neutral-455 btn-close"
      un-hover="text-white"
      width="18"
      height="18"
      @click="$emit('close')"
    />
    <div class="mx-2">
      <h3 class="font-800 text-white text-2xl mb-8 flex items-center">
        Account
      </h3>
      <p class="text-base font-400 text-neutral-310">
        Connected with {{ connectWallet.label }}
      </p>
      <div class="flex items-center mt-6 mb-8">
        <SvgIcon
          name="avatar"
          class="rounded-full overflow-hidden"
          width="32"
          height="32"
        />
        <span
          class="flex-1 text-left mx-4 font-800 text-white text-lg truncate"
        >
          {{
            // @ts-ignore
            connectWallet.id(store.user)
          }}
        </span>
        <a
          class="h-8 font-600 text-sm text-yellow-550 border border-yellow-550 border-opacity-30 rounded-2.5xl inline-flex items-center justify-center px-3"
          un-hover="border-opacity-100"
          @click="$emit('disconnect')"
        >
          Disconnect
        </a>
      </div>
    </div>
    <a
      class="flex items-center justify-between h-14 rounded-3 box-border bg-dark-401 mt-4.5 px-7.5 font-400 text-white text-base opacity-50"
      @click.stop="
        copy(
          // @ts-ignore
          connectWallet.address(store.user)
        )
      "
    >
      <span class="flex-1 text-left">Copy Address</span>
      <SvgIcon name="address" width="18" height="18" />
    </a>
    <a
      v-if="menu.link"
      :href="accountAddress"
      target="_blank"
      class="flex items-center justify-between h-14 rounded-3 box-border bg-dark-401 mt-4.5 px-7.5 font-400 text-white text-base opacity-50"
    >
      <span class="flex-1 text-left">View on Explorer</span>
      <SvgIcon name="top-right-arrow" width="18" height="18" />
    </a>
  </div>
</template>

<style>
#account a {
  cursor: pointer;
  transition: opacity 0.2s ease, border-color 0.2s ease;
}

#account a:hover {
  opacity: 1;
}
</style>

<script setup lang="ts">
import { computed } from 'vue'
import { MENUS } from '@/constants'
import { useStarknet, useStore } from '@/stores'
import { constants } from 'starknet'

interface Props {
  network: number
  wallet: number
}

const props = withDefaults(defineProps<Props>(), {
  network: 0,
  wallet: 0,
})

const store = useStore()
const starknet = useStarknet()

const menu = computed(() => MENUS[props.network])
const connectWallet = computed(() => menu.value.children[props.wallet])

const copy = (text: string) => navigator.clipboard.writeText(text)

const accountAddress = computed(() => {
  if (store.type === 'mvm') {
    return 'https://scan.mvm.dev/address/' + store.user?.user_id
  }
  if (store.type === 'starknet') {
    let explorerUrl = starknet.network === constants.NetworkName.SN_GOERLI ? "https://testnet.starkscan.co" : "https://starkscan.co"
    return `${explorerUrl}/contract/${store.user?.user_id}`
  }
  return MENUS[props.network].link
})
</script>
